import * as _crypto2 from "@near-js/crypto";
var _crypto = _crypto2;
try {
  if ("default" in _crypto2) _crypto = _crypto2.default;
} catch (e) {}
import * as _keystores2 from "@near-js/keystores";
var _keystores = _keystores2;
try {
  if ("default" in _keystores2) _keystores = _keystores2.default;
} catch (e) {}
var exports = {};
var __awaiter = exports && exports.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BrowserLocalStorageKeyStore = void 0;
const crypto_1 = _crypto;
const keystores_1 = _keystores;
const LOCAL_STORAGE_KEY_PREFIX = "near-api-js:keystore:";
/**
 * This class is used to store keys in the browsers local storage.
 *
 * @see [https://docs.near.org/docs/develop/front-end/naj-quick-reference#key-store](https://docs.near.org/docs/develop/front-end/naj-quick-reference#key-store)
 * @example
 * ```js
 * import { connect, keyStores } from 'near-api-js';
 *
 * const keyStore = new keyStores.BrowserLocalStorageKeyStore();
 * const config = {
 *   keyStore, // instance of BrowserLocalStorageKeyStore
 *   networkId: 'testnet',
 *   nodeUrl: 'https://rpc.testnet.near.org',
 *   walletUrl: 'https://wallet.testnet.near.org',
 *   helperUrl: 'https://helper.testnet.near.org',
 *   explorerUrl: 'https://explorer.testnet.near.org'
 * };
 *
 * // inside an async function
 * const near = await connect(config)
 * ```
 */
class BrowserLocalStorageKeyStore extends keystores_1.KeyStore {
  /**
   * @param localStorage defaults to window.localStorage
   * @param prefix defaults to `near-api-js:keystore:`
   */
  constructor(localStorage = window.localStorage, prefix = LOCAL_STORAGE_KEY_PREFIX) {
    super();
    this.localStorage = localStorage;
    this.prefix = prefix;
  }
  /**
   * Stores a {@link KeyPair} in local storage.
   * @param networkId The targeted network. (ex. default, betanet, etc…)
   * @param accountId The NEAR account tied to the key pair
   * @param keyPair The key pair to store in local storage
   */
  setKey(networkId, accountId, keyPair) {
    return __awaiter(this, void 0, void 0, function* () {
      this.localStorage.setItem(this.storageKeyForSecretKey(networkId, accountId), keyPair.toString());
    });
  }
  /**
   * Gets a {@link KeyPair} from local storage
   * @param networkId The targeted network. (ex. default, betanet, etc…)
   * @param accountId The NEAR account tied to the key pair
   * @returns {Promise<KeyPair>}
   */
  getKey(networkId, accountId) {
    return __awaiter(this, void 0, void 0, function* () {
      const value = this.localStorage.getItem(this.storageKeyForSecretKey(networkId, accountId));
      if (!value) {
        return null;
      }
      return crypto_1.KeyPair.fromString(value);
    });
  }
  /**
   * Removes a {@link KeyPair} from local storage
   * @param networkId The targeted network. (ex. default, betanet, etc…)
   * @param accountId The NEAR account tied to the key pair
   */
  removeKey(networkId, accountId) {
    return __awaiter(this, void 0, void 0, function* () {
      this.localStorage.removeItem(this.storageKeyForSecretKey(networkId, accountId));
    });
  }
  /**
   * Removes all items that start with `prefix` from local storage
   */
  clear() {
    return __awaiter(this, void 0, void 0, function* () {
      for (const key of this.storageKeys()) {
        if (key.startsWith(this.prefix)) {
          this.localStorage.removeItem(key);
        }
      }
    });
  }
  /**
   * Get the network(s) from local storage
   * @returns {Promise<string[]>}
   */
  getNetworks() {
    return __awaiter(this, void 0, void 0, function* () {
      const result = new Set();
      for (const key of this.storageKeys()) {
        if (key.startsWith(this.prefix)) {
          const parts = key.substring(this.prefix.length).split(":");
          result.add(parts[1]);
        }
      }
      return Array.from(result.values());
    });
  }
  /**
   * Gets the account(s) from local storage
   * @param networkId The targeted network. (ex. default, betanet, etc…)
   */
  getAccounts(networkId) {
    return __awaiter(this, void 0, void 0, function* () {
      const result = new Array();
      for (const key of this.storageKeys()) {
        if (key.startsWith(this.prefix)) {
          const parts = key.substring(this.prefix.length).split(":");
          if (parts[1] === networkId) {
            result.push(parts[0]);
          }
        }
      }
      return result;
    });
  }
  /**
   * @hidden
   * Helper function to retrieve a local storage key
   * @param networkId The targeted network. (ex. default, betanet, etc…)
   * @param accountId The NEAR account tied to the storage key that's sought
   * @returns {string} An example might be: `near-api-js:keystore:near-friend:default`
   */
  storageKeyForSecretKey(networkId, accountId) {
    return `${this.prefix}${accountId}:${networkId}`;
  }
  /** @hidden */
  *storageKeys() {
    for (let i = 0; i < this.localStorage.length; i++) {
      yield this.localStorage.key(i);
    }
  }
}
exports.BrowserLocalStorageKeyStore = BrowserLocalStorageKeyStore;
export default exports;